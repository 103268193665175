/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import {
  DEPARTMENT_CODES,
  type HolidaysWithIsoCodesInterface,
} from "@soliguide/common";

import { LocationService } from "./location.service";
import { THEME_CONFIGURATION } from "../../../models";

@Injectable({
  providedIn: "root",
})
export class HolidaysService {
  private holidayInformation: Observable<{
    isTodayHoliday: boolean;
    stringToDisplay: string;
    listDepHolidays: string[];
    holidayName: string;
  }>;

  // date for search holidays information is the current date
  private today = new Date();

  constructor(
    private readonly translateService: TranslateService,
    private readonly locationService: LocationService
  ) {
    this.holidayInformation = this.searchTodayHolidayInformation();
  }

  private searchTodayHolidayInformation = () => {
    return this.locationService
      .getDayHolidayInformation(THEME_CONFIGURATION.country, this.today)
      .pipe(map((holidays) => this.handleHoliday(holidays)));
  };

  public getHolidayInformation = () => {
    return this.holidayInformation;
  };

  public isTodayHolidayForPostalCode = (
    postalCode: string
  ): Observable<boolean> => {
    if (!postalCode) {
      return of(false);
    }
    return this.locationService.isDayHolidayForPostalCode(
      THEME_CONFIGURATION.country,
      this.today,
      postalCode
    );
  };

  public handleHoliday = (holidays: HolidaysWithIsoCodesInterface) => {
    const territoriesHolidays = holidays.territoriesHolidays;
    if (!territoriesHolidays.length) {
      return {
        stringToDisplay: "",
        listDepHolidays: [],
        holidayName: "",
        isTodayHoliday: false,
      };
    }

    let stringToDisplay = "";
    let listDepHolidays: string[];
    let holidayName = "";
    let isTodayHoliday = false;

    // [0] because there is only Public Holidays without School Holidays (for now)
    const territoryHolidays = territoriesHolidays[0];

    holidayName =
      territoryHolidays?.name?.find(
        (name) => name.language === THEME_CONFIGURATION.country.toUpperCase()
      )?.text ?? "";

    isTodayHoliday = true;

    if (territoryHolidays?.subdivisions?.length) {
      const isoCodes = holidays.isoCodes;

      listDepHolidays = isoCodes.map((isoCode) => isoCode.code);
      if (isoCodes.length === 1) {
        stringToDisplay = this.translateService.instant(
          "DEPARTMENT_PUBLIC_HOLIDAY", //"Jour férié pour le département : "
          { department: isoCodes[0].name }
        );
      } else {
        stringToDisplay = this.translateService.instant(
          "DEPARTMENTS_PUBLIC_HOLIDAY", //"Jour férié pour les départements : "
          { departments: isoCodes.map((isoCode) => isoCode.name).join(", ") }
        );
      }
    } else {
      stringToDisplay = this.translateService.instant("PUBLIC_HOLIDAY"); // "Jour férié"
      listDepHolidays = DEPARTMENT_CODES[THEME_CONFIGURATION.country];
    }

    return {
      stringToDisplay,
      listDepHolidays,
      holidayName,
      isTodayHoliday,
    };
  };
}
